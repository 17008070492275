export const fadeIn = (el, duration = 1000) => {
    el.style.opacity = 0;
    el.style.display = 'block';

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = elapsed / duration;

        el.style.opacity = Math.min(progress, 1);

        if (progress < 1) {
            window.requestAnimationFrame(animate);
        }
    }

    window.requestAnimationFrame(animate);
};

export const fadeOut = (el, duration = 1000) => {
    el.style.opacity = 1;

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = elapsed / duration;

        el.style.opacity = 1 - Math.min(progress, 1);

        if (progress < 1) {
            window?.requestAnimationFrame(animate);
        } else {
            el.style.display = 'none';
        }
    }

    window?.requestAnimationFrame(animate);
};

export const slideInFromRight = (el, duration = 1000, callback = () => {}) => {
    el.style.transform = 'translateX(100%)';
    el.style.display = 'block';

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const translateValue = 100 - progress * 100;

        el.style.transform = `translateX(${translateValue}%)`;

        if (progress < 1) {
            window.requestAnimationFrame(animate);
            return;
        }

        el.style.transform = 'translateX(0)';

        callback();
    }

    window.requestAnimationFrame(animate);
};

export const slideOutToRight = (el, duration = 1000, parentEl = null, callback = () => {}) => {
    el.style.transform = 'translateX(0)';

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const translateValue = progress * 100;
        el.style.transform = `translateX(${translateValue}%)`;

        if (progress < 1) {
            window.requestAnimationFrame(animate);
            return;
        } 

        el.style.display = 'none';

        if (parentEl) {
            parentEl.style.display = 'none';
        }

        callback();
    }

    window.requestAnimationFrame(animate);
};

export default {
    fadeIn,
    fadeOut,
    slideInFromRight,
    slideOutToRight
};
